<template>
  <div id="main">
    <!-- 顶部 -->
    <div class="header" v-show="header">
      <div class="header-left">
        <!-- 菜单开关 -->
        <div class="menu-switch" @click="drawer=!drawer">
          <i class="iconfont icon_menu"></i>
        </div>
        <span class="title">{{logo.mainTitle?logo.mainTitle:'IOT在线监测系统'}}</span>
        <!-- <span class="visual" @click="gotoVisualPage">大屏可视化</span> -->
        <router-link class="visual" to="/visual">大屏可视化</router-link><!-- target="_blank" -->
        <!--<router-link class="visual" to="/public/typhoon_path" >台风路径</router-link>-->
        <!-- target="_blank" -->
        <!-- 功能栏 -->
        <div v-if="customizedMenus">
          <!-- <el-button type="text" class="visual" @click="menusBtnClick('sys')" size="small ">系统概况</el-button> -->
          <!-- <el-button type="text" class="visual" @click="menusBtnClick('gps_data')" size="small ">GIS地图</el-button> -->
          <!-- <el-button type="text" class="visual" @click="menusBtnClick('picture_datase')" size="small ">图像监视</el-button>
          <el-button type="text" class="visual" @click="menusBtnClick('video_data')" size="small ">视频监控</el-button> -->
          <el-button type="text" class="visual" @click="menusBtnClick('history_data1')" size="small ">历史查询</el-button>
          <!-- <el-button type="text" class="visual" @click="menusBtnClick('report')" size="small">统计报表</el-button> -->
        </div>

        <!-- 大屏可视化 -->
        <!-- <span class="visual">
					<el-tooltip class="item" content="大屏可视化">
						<el-button type="primary" icon="el-icon-pie-chart" circle size="mini"></el-button>
					</el-tooltip>
				</span> -->
      </div>
      <div class="header-right">
        <table>
          <tr>
            <td>
              <!-- 换肤 -->
              <Theme :color="color" @color-update="colorChange"></Theme>
            </td>
            <td>
              <!-- 通知 -->
              <el-tooltip v-if="alarmList.length>0" class="" content="通知">
                <el-badge :value="alarmList.length" class="">
                  <i class="notify" :class="notify?'el-icon-bell':'el-icon-close-notification'" @click="notify=!notify"></i>
                </el-badge>
              </el-tooltip>
              <i v-else class="notify" :class="notify?'el-icon-bell':'el-icon-close-notification'" @click="notify=!notify"></i>

            </td>
            <td>
              <!-- 设置 -->
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <span class="el-icon-s-custom"></span>
                  <span class="set">{{user.nickname}} <span v-if="user.isManager == 1">(管理员)</span> </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="phone">短信通知
                  </el-dropdown-item>
                  <el-dropdown-item command="visual">大屏配置
                  </el-dropdown-item>
                  <el-dropdown-item command="reset" v-if="user.loginMode=='pwd'">修改密码
                  </el-dropdown-item>
                  <el-dropdown-item command="exit">退出账号</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 主体 -->
    <div class="container" ref="container" v-loading.fullscreen.lock="loading" element-loading-text="请求中，请稍后" element-loading-background="rgba(255, 255, 255, 0.7)">
      <!-- 菜单抽屉 -->
      <el-drawer title="我是标题" direction="ltr" size="240px" :visible.sync="drawer" :with-header="false">
        <Menu @change="menuChange"></Menu>
      </el-drawer>
      <!-- 左侧设备列表 -->
      <div class="list" v-show="treeList">
        <Tree></Tree>
      </div>
      <div class="c-switch">
        <div @click="treeList=!treeList">
          <i :class="treeList?'el-icon-arrow-left':'el-icon-arrow-right'"></i>
        </div>
      </div>
      <div class="content-outer">
        <!-- 路由标签 -->
        <div class="route-tab" v-show="route_tab">
          <RouteTag></RouteTag>
        </div>
        <!-- 数据展示 -->
        <div class="content" style="padding: 0;" v-if="this.$route.name=='GpsData'">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </div>
        <div class="content" v-else>
          <!-- 设备路径 -->
          <div class="dev_url">
            <div class="node-path">
              <span class="back" v-show="isBack" @click="onBack">
                <el-link icon="el-icon-back" type="primary">返回</el-link>
              </span>
              <i class="iconfont icon_device"></i>
              <span>{{nodePath}}</span>
            </div>
            <div class="refresh" @click="refresh" v-if="v_refresh"> <img src="../../src/assets/refresh.png" alt="即时召测" class="refresh_icon"> 即时召测</div>
          </div>
          <br />
          <keep-alive>
            <router-view ref="view" v-if="$route.name!='VideoData'"></router-view>
          </keep-alive>
          <router-view ref="view" v-if="$route.name=='VideoData'"></router-view>
        </div>

      </div>
      <!-- 右侧报警日志 -->
      <el-collapse-transition>
        <div class="log" v-show="notify">
          <div class="show_box">
            <div class="log_title"><i class="el-icon-warning-outline"></i><span>报警设备</span></div>
            <div>
              <Report :alarmList="alarmList" />
            </div>
          </div>
          <!-- <div class="show_box">
						<div class="log_title"><i class="el-icon-edit-outline"></i><span>日志</span></div>
						<div><Log /></div>
					</div> -->

        </div>
      </el-collapse-transition>
    </div>
    <!-- 修改密码Dialog 惰性加载-->
    <Dialog v-if="resetDialog.show" :data="resetDialog" @sure="resetReady">
      <template>
        <div style="padding: 0 10%;">
          <div class="c-item">
            <el-input placeholder="请输入旧密码" v-model="resetDialog.data.oldPwd">
              <template slot="prepend" show-password>旧的密码</template>
            </el-input>
          </div>
          <div class="c-item">
            <el-input placeholder="请输入新密码" v-model="resetDialog.data.newPwd" @change="passwordVerify">
              <template slot="prepend" show-password>新的密码</template>
            </el-input>
          </div>
          <p class="c-tips">*密码长度至少8位，由数字、字母或特殊字符中2种方式的组合</p>

          <div class="c-item">
            <el-input placeholder="请确认新密码" v-model="resetDialog.data.confirmPwd">
              <template slot="prepend" show-password>确认密码</template>
            </el-input>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 设置预警手机号Dialog 惰性加载-->
    <Dialog v-if="phoneDialog.show" :data="phoneDialog" @sure="phoneReady">
      <template>
        <div style="padding: 0 10%;">
          <div class="c-item">
            <el-input placeholder="请填写手机号" v-model="phoneDialog.data.phone">
              <template slot="phone">手机号</template>
            </el-input>
          </div>

        </div>
      </template>
    </Dialog>
    <!--大屏配置Dialog 惰性加载-->
    <Visual v-if="visualConfigShow" :dialogVisible="visualConfigShow" @sure="visualReady"></Visual>
  </div>
</template>

<script>
const Theme = () => import('../components/Theme.vue'); //主题
const Menu = () => import('../components/Menu.vue'); //菜单
const RouteTag = () => import('../components/RouteTag.vue'); //路由标签
const Log = () => import('../components/Log.vue'); //日志
const Tree = () => import('../components/Tree.vue'); //树列表
const Dialog = () => import('../components/Dialog.vue'); //对话框
const Report = () => import('../components/Report.vue'); // 报警
const Visual = () => import('../components/Visual/visual.config.vue');//大屏配置

export default {
  data() {
    return {
      treeList: true, //树列表
      route_tab: true,//路由标签
      header: true,  //顶部
      notify: false, //报警通知
      drawer: false, //菜单抽屉
      color: '#409EFF', //主题颜色
      customizedMenus: false, // 是否显示定制菜单
      nodePath: '全部设备', //节点路径
      logo: '', //平台信息
      user: '', //账号信息
      // 修改密码dialog
      resetDialog: {
        show: false,
        title: '修改密码',
        width: '550px',
        data: {
          oldPwd: '',
          newPwd: '',
          confirmPwd: ''
        }
      },
      //预警手机号
      phoneDialog: {
        show: false,
        title: '手机号(仅用于接收设备报警信息)',
        width: '450px',
        data: {
          phone: ""
        }
      },
      visualConfigShow: false,  //大屏配置
      loading: false,
      isBack: false, //返回按钮
      v_refresh: false,
      route_url: ['LiveData'],
      alarmList: [],
    }
  },
  components: {
    Theme,
    Menu,
    RouteTag,
    Log,
    Tree,
    Dialog,
    Report,
    Visual
  },
  watch: {
    "$route": {
      handler: function () {
        // 实时数据，开启刷新
        if (this.route_url.includes(this.$route.name)) {
          this.v_refresh = true
        } else {
          this.v_refresh = false
        }
      }
    },
    "$store.state.settingStore.fullscreen": {  //监听是否全屏化
      handler: function () {
        let isFullScreen = this.$store.state.settingStore.fullscreen  //获取全局存储
        this.treeList = !isFullScreen
        this.route_tab = !isFullScreen
        this.header = !isFullScreen
        if (isFullScreen) {     //去掉顶部padding-top:50px
          this.$refs.container.style.paddingTop = 0
        }
      }
    },
    "$store.state.alarmData": {  //监听报警变化
      handler: function (newVal, oldVal) {
        this.alarmList = this.$store.state.alarmData //获取全局存储
        //有报警时自动打开报警边栏
        if (this.alarmList.length > 0) {
          this.notify = true
        }
      },
    }
  },
  computed: {

  },
  methods: {
    // 换肤
    colorChange(color) {
      this.color = color;
      this.common.cSetThemeColor(color);
    },

    // 切换菜单
    menuChange(index) {
      this.drawer = false;
    },
    // 个人中心下拉菜单
    handleCommand(command) {
      this.getuserProfile()
      switch (command) {
        case "reset":
          this.resetDialog.show = true;
          break;
        case "phone":
          this.phoneDialog.show = true;
          break;
        case "visual":
          this.visualConfigShow = true;
          break;
        case "exit":
          let json = {
            "cmd": "userLogout"
          }
          this.ws.send(json);
          this.loading = true;
          break;
        default:
          break;
      }
    },
    // 修改密码
    resetReady() {
      let data = this.resetDialog.data;
      if (data.oldPwd == "") {
        this.common.cToast(this, '请输入旧密码');
        return;
      }
      if (data.newPwd == "") {
        this.common.cToast(this, '请输入新密码');
        return;
      }
      //密码强度校验
      if (!this.common.cIsPassword(data.newPwd)) {
        this.common.cToast(this, "密码最少8位，需要包含数字、字母或特殊字符至少两种组合");
        return false
      }
      if (data.newPwd != data.confirmPwd) {
        this.common.cToast(this, '两次密码输入不一致');
        return;
      }
      let json = {
        "cmd": "editPassword",
        "password": data.oldPwd,
        "newPassword": data.newPwd
      }
      this.ws.send(json);
      this.loading = true;
    },
    //密码强度验证
    passwordVerify() {
      let password = this.resetDialog.data.newPwd
      let isVerify = this.common.cIsPassword(password)
      if (!isVerify) {
        this.common.cToast(this, "密码最少8位，需要包含数字、字母或特殊字符至少两种组合");
      }
    },
    // 设置预警手机号
    phoneReady() {
      let data = this.phoneDialog.data;
      if (data.phone.length != 0 && data.phone.length != 11) {
        this.common.cToast(this, '手机号无效');
        return;
      }
      let json = {
        "cmd": "setUserConfig",
        "tag": "phone",
        "value": data.phone
      }
      this.ws.send(json);
      this.loading = true;
    },
    //大屏配置完成
    visualReady() {
      this.visualConfigShow = false
    },
    /**
           * @description: 主菜单按钮事件
           * @param {*} type 按钮类型
           * @return {*}
           * @author: Sunwx
           */
    menusBtnClick(type) {
      var flag = true;
      switch (type) {
        case "sys":
          break;
        case "gps_data":
          flag = false;
          break;
        case "typhoon_path":
          flag = false;
          break;
        case "picture_datase":
          flag = true;
          break;
        case "video_data":
          flag = false;
          break;
        case "history_data1":
          flag = false;
          break;
        case "report":
          break;
        default:
          this.$message({
            message: '请联系管理员进行配置',
            type: 'error'
          });
          return;
      }
      if (flag) {
        this.$router.push({
          path: '/second/' + type
        })
      } else {
        let path;
        if (type == 'history_data1') {
          path = '/public/history_data1?type=sensor'  // 历史数据
        } else {
          path = '/public/' + type
        }
        ({ path })
      }

    },
    // 更新设备路径
    setNodePath(path) {
      this.nodePath = path;
    },
    // 打开大屏可视化界面
    gotoVisualPage() {
      let routeUrl = this.$router.resolve({
        path: "/visual"
      });
      window.open(routeUrl.href, '_blank');
    },
    //获取账号信息
    getuserProfile() {
      let json = {
        "cmd": "userProfile",
      }
      this.ws.send(json);
    },
    // 返回按钮点击/显示/隐藏
    onBack() {
      this.hideBack();
      this.$refs.view.onBack();
    },
    showBack() {
      this.isBack = true;
    },
    hideBack() {
      this.isBack = false;
    },
    // 刷新
    refresh() {
      console.log('refresh,刷新！')
    },
    getPadding() {
      // console.log(111111);
      if (this.header) {
        return { paddingTop: 0, }
      } else {
        return { paddingTop: 50 + "px", }
      }
    }
  },
  created() {
    // 登录状态
    let user = this.common.cGetUser();
    // let wxToken = sessionStorage.getItem("wxToken");
    // if ((user.loginMode == "pwd" && user.username == "") || (user.loginMode == "code" && user.wxToken == "")) {
    //   ({
    //     path: '/'
    //   })
    // }
    // 是否显示定制菜单
    this.customizedMenus = this.common.featureEnabled('customizedMenus');
    // 实时数据，开启刷新
    if (this.route_url.includes(this.$route.name)) {
      this.v_refresh = true
    } else {
      this.v_refresh = false
    }

  },
  mounted() {
    this.logo = this.$store.getters['settingStore/getLogo'];
    this.user = this.common.cGetUser();
    this.alarmList = this.$store.state.alarmData //获取全局存储
    // 修改密码result
    this.ws.addCallback('editPasswordAck', (res) => {
      this.loading = false;
      if (this.common.cGetResult(res)) {
        this.common.cToast(this, '修改成功，请重新登录', 'success');
        // 退出登录
        // 重新登录
        setTimeout(() => {
          let json = {
            "cmd": "userLogout"
          }
          this.ws.send(json);
        }, 1000)
      } else {
        if (res.errorName == "EPasswordWrong") {
          this.common.cToast(this, '旧密码错误');
        } else {
          this.common.cResultNo(this);
        }
      }
    })
    // 设置预警手机号result
    this.ws.addCallback('setUserConfigAck', (res) => {
      this.loading = false;
      if (this.common.cGetResult(res)) {
        this.common.cToast(this, '修改成功', 'success');
        setTimeout(() => {
          this.phoneDialog.show = false
        }, 800)
      } else {
        if (res.error == "EUnknownTag") {
          this.common.cToast(this, '设置失败');
        } else {
          this.common.cResultNo(this);
        }
      }
    })
    // 退出账号result
    this.ws.addCallback('userLogoutAck', (res) => {
      this.loading = false;
      //清理设备树
      this.$store.commit('setTreeData', []);
      //清理设备选择  
      this.$store.commit('setCurTreeData', []);

      if (this.common.cGetResult(res)) {
        this.$router.push({
          path: '/'
        })
      }
    })
    //账号信息result
    this.ws.addCallback('userProfileAck', (res) => {
      // console.log("userProfileAck:", res.phone);
      if (res.phone != "") {
        this.phoneDialog.data.phone = res.phone
      }
    })
  }
}
</script>

<style lang="scss">
$header_h: 50px; //顶部高度
$border_color: #e6e6e6;

@import "../assets/iconfont/icon_menu.css";
@import "../assets/iconfont/icon_device.css";

#main {
  background-color: #f5f6f9 !important;

  // 顶部
  .header {
    width: 100%;
    height: $header_h;
    background-color: $color_white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    box-sizing: border-box;
    box-shadow: 0 2px 4px $border_color;
    position: fixed;
    z-index: 1000;

    // 顶部左侧——菜单/标题
    .header-left {
      display: flex;
      flex-direction: row;

      .menu-switch {
        width: 50px;
        height: 100%;
        background-color: $color_theme;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        .icon_menu:before {
          font-size: 28px !important;
          color: $color_white !important;
        }
      }

      .title {
        line-height: $header_h;
        color: $color_theme;
        font-size: 22px;
        font-weight: bold;
        margin: 0 15px;
      }

      // 大屏可视化
      .visual {
        line-height: $header_h;
        padding: 0 10px;
        margin-left: 20px;
        color: $color_theme;
        text-decoration: none;
        font-size: 10px;
        &:hover {
          cursor: pointer;
          background-color: rgba($color: $color_black, $alpha: 0.03);
        }
      }
    }

    // 顶部右侧
    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      table tr td {
        padding: 0 15px;
      }

      i,
      span {
        font-size: 20px;
        color: rgba($color: $color_main, $alpha: 0.7);
      }

      i.notify {
        font-weight: bold;
      }
      .set,
      .set span {
        font-size: 14px;
      }
      .set {
        margin-left: 8px;
        cursor: default;
      }

      .el-color-picker--mini {
        span {
          color: $color_white;
        }
      }
    }
  }

  // 主体
  .container {
    overflow: auto;
    height: calc(100vh - #{$header_h});
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-top: $header_h;

    // 左侧设备列表
    // .list {
    // }

    .content-outer {
      flex: auto;
      display: flex;
      flex-direction: column;
      min-width: 1000px;
      position: relative;
      overflow: auto;

      // 路由标签
      .route-tab {
        background-color: #ffffff;

        .el-tabs__header {
          margin: 0;
        }
      }
    }

    .content {
      height: 100%;
      position: relative;
      padding: 15px 20px;

      // 设备路径
      .node-path {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color_theme;

        .back {
          margin-right: 20px;

          i {
            font-size: 14px;
            padding-right: 0;
          }
        }

        i {
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }

    // 右侧报警日志
    .log {
      width: 220px;
      height: 100%;
      box-sizing: border-box;
      background-color: $color_white;
      border-left: 1px solid $border_color;
      padding: 10px;
      flex-shrink: 0;
      overflow: auto;
    }
  }

  .menu-switch,
  .el-tooltip,
  .el-dropdown {
    &:hover {
      @include c-hover;
    }
  }
}
.show_box {
  margin-bottom: 10px;
  .log_title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    span {
      margin-left: 5px;
    }
    margin-bottom: 5px;
  }
}
.dev_url {
  display: flex;
  align-items: center;
  .refresh {
    margin-left: 20px;
    color: #409eff;
    &:hover {
      cursor: pointer;
    }
  }
  .refresh_icon {
    width: 15px;
    height: 15px;
    position: relative;
    top: 3px;
  }
}
.c-tips {
  font-size: 12px;
  text-align: right;
  color: #ff7a7a;
}
</style>
